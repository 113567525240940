import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2018-12-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/reading/dodo.jpg",
        "alt": "The Rise and Fall of D.O.D.O book cover"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`The Rise and Fall of D.O.D.O`}</strong></li>
      <li parentName="ul">{`Neil Stephenson & Nicole Galland`}</li>
    </ul>
    <p>{`My rating: ★★★★★`}</p>
    <p>{`A unique take on time travel by adding the science of magic into the mix. The story is told through first person accounts and descriptions of artifacts. I could see this having a sequal. Hope so.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      